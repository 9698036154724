import React, { useState } from "react";

export default function ReferralSource({ register, errors }) {
  const fieldName = "referralSource";
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <div className="p-4 rounded">
      <h2 className="text-2xl font-semibold mb-4 text-white fade-in-up">
        And finally, where did you hear about us? *
      </h2>
      <div className="space-y-3">
        {/* List of radio buttons */}
        {[
          { value: "googleSearch", label: "Google search" },
          { value: "recommendation", label: "Recommendation / word of mouth" },
          { value: "blogArticle", label: "One of your blog articles" },
          { value: "socialMedia", label: "Social media" },
          { value: "email", label: "You sent me an email" },
          { value: "other", label: "Other" },
        ].map((option) => (
          <div
            key={option.value}
            className="flex items-center pl-4 border border-orange-500 rounded mb-2"
          >
            <input
              id={`${fieldName}-${option.value}`}
              type="radio"
              value={option.value}
              className="w-5 h-5 text-orange-500 bg-gray-700 border-orange-500 rounded focus:ring-orange-500 focus:ring-2"
              onChange={() => setSelectedOption(option.value)}
              {...register(`${fieldName}.choice`, { required: true })}
            />
            <label
              htmlFor={`${fieldName}-${option.value}`}
              className="w-full py-4 ml-2 text-lg font-medium text-white"
            >
              {option.label}
            </label>
          </div>
        ))}

        {errors[fieldName] && (
          <span className="text-red-400">This field is required</span>
        )}

        {selectedOption === "other" && (
          <div className="mt-4">
            <input
              type="text"
              placeholder="Please specify"
              className="w-full bg-gray-700 text-white px-4 py-3 rounded-md"
              {...register(`${fieldName}.otherText`)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
