import React from "react";
import TitleBlock from "../blocks/TitleBlock";

export default function CompanyOverview({ register, errors }) {
  const fieldName = "companyoverview";

  return (
    <div className="relative fade-in-up">
      <TitleBlock title={"Please give a brief overview of your company *"} />
      <div className="relative">
        <textarea
          className="bg-black  w-full text-orange-400 placeholder-grey-600 focus:placeholder-gray-500 py-5 text-3xl md:text-2xl border-0 border-b-2 border-orange-500 focus:border-0 focus:border-b-4 focus:border-orange-500 focus:outline-none"
          rows="10"
          {...register(fieldName, { required: true })}
        />
        {errors[fieldName] && (
          <span className="text-red-500">This field is required</span>
        )}
      </div>
    </div>
  );
}
