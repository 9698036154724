import TitleBlock from "../blocks/TitleBlock";

export default function Functionality({ register, errors }) {
  const fieldName = "functionality";

  return (
    <div className="relative fade-in-up">
      <TitleBlock
        title={
          "Are there any other specific technical features or requirements needed?"
        }
        subtitle={"If nothing, you may just enter 'no'"}
      />
      <div>
        <textarea
          className="bg-black  w-full text-orange-400 placeholder-grey-600 focus:placeholder-gray-500 py-5 text-3xl md:text-2xl border-0 border-b-2 border-orange-500 focus:border-0 focus:border-b-4 focus:border-orange-500 focus:outline-none"
          rows={5}
          {...register(fieldName, { required: true })}
        />
        {errors[fieldName] && <span>This field is required</span>}
      </div>
    </div>
  );
}
