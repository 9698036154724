import React from "react";
import TitleBlock from "../blocks/TitleBlock";

export default function CompanyName({ register, errors }) {
  const fieldName = "companyname";

  return (
    <div>
      <TitleBlock title={"What's your Company Name ?"} />
      <div className="relative fade-in-up">
        <input
          className="bg-black text-center w-full text-orange-400 placeholder-grey-600 focus:placeholder-gray-500 py-4 text-3xl md:text-5xl border-0 border-b-2  border-orange-500  focus:border-b-4 focus:border-orange-500 focus:outline-none"
          {...register(fieldName, { required: true })}
          placeholder="Enter company name here"
        />
        {errors[fieldName] && (
          <span className="text-red-500">This field is required</span>
        )}
      </div>
    </div>
  );
}
