import TitleBlock from "../blocks/TitleBlock";

export default function ClientPhone({ register, errors }) {
  const fieldName = "clientphone";

  return (
    <div className="fade-in-up">
      <TitleBlock title={"What's the best phone Number to contact you on?"} />
      <div className="relative">
        <input
          className="bg-black text-center w-full text-orange-400 placeholder-grey-600  focus:placeholder-gray-500 py-5  text-3xl md:text-4xl border-0 border-b-2 border-orange-500 focus:border-0 focus:border-b-4 focus:border-orange-500 focus:outline-none"
          {...register(fieldName, { required: true })}
        />
        {errors[fieldName] && <span>This field is required</span>}
      </div>
    </div>
  );
}
