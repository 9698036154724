// NextButton.js
import React from "react";

function NextButton({ onClick, text }) {
  return (
    <div className="flex justify-center">
      <button
        type="button"
        className="bg-gradient-to-r from-orange-400 to-red-500 text-white font-sans py-4 px-6 rounded-full"
        onClick={onClick}
      >
        {text || "Next"}
      </button>
    </div>
  );
}

export default NextButton;
