import "./App.css";
import RequirementForm from "./form/form";
import backgroundImage from "./images/background.jpg";

function App() {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
      className="min-h-screen flex flex-col items-center justify-center bg-black relative  "
    >
      <div className="wdc w-full max-w-2xl text-white py-8 shadow-lg ">
        {/* Requirement Form */}
        <RequirementForm />
      </div>

      {/* Footer */}
      <div className="absolute bottom-4  text-center">
        <p className="text-gray-500 text-sm">
          <span>Version 2.6.0 | © 2024 </span>
          <a href="http://webdesigncollective.com.au">Web Design Collective</a>
          <span> | </span>
          <a href="https://connectivai.com">ConnectivAI</a>
        </p>
      </div>
    </div>
  );
}

export default App;
