export default function QuestionSelling({ register, errors }) {
  const fieldName = "questionselling";

  return (
    <div className="p-4  rounded fade-in-up">
      <h2 className="text-2xl font-semibold mb-4 text-white">
        Will you be selling things on the site? *
      </h2>

      <div className="space-y-4">
        {/* Radio for 'Yes' */}
        <div className="flex items-center pl-4 border border-orange-500 rounded">
          <input
            id={`${fieldName}-yes`}
            type="radio"
            className="w-5 h-5 text-orange-500 bg-gray-700 border-orange-500 rounded focus:ring-orange-500 focus:ring-2"
            value="yes"
            {...register(`${fieldName}`, { required: true })}
          />
          <label
            htmlFor={`${fieldName}-yes`}
            className="w-full py-4 ml-2 text-lg font-medium text-white"
          >
            Yes
          </label>
        </div>

        {/* Radio for 'No' */}
        <div className="flex items-center pl-4 border border-orange-500 rounded">
          <input
            id={`${fieldName}-no`}
            type="radio"
            className="w-5 h-5 text-orange-500 bg-gray-700 border-orange-500 rounded focus:ring-orange-500 focus:ring-2"
            value="no"
            {...register(`${fieldName}`, { required: true })}
          />
          <label
            htmlFor={`${fieldName}-no`}
            className="w-full py-4 ml-2 text-lg font-medium text-white"
          >
            No
          </label>
        </div>

        {/* Error message */}
        {errors[fieldName] && (
          <span className="text-red-400 mt-2 block">
            This field is required
          </span>
        )}
      </div>
    </div>
  );
}
