// import React, { useState } from "react";

// export default function Submit() {
//   const [submitted, setSubmitted] = useState(false);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setSubmitted(true);
//     // Any other logic that should run upon submission can be added here

//   };

//   if (submitted) {
//     return (
//       <div className="mt-4 p-6 rounded-md shadow-md">
//         <h2 className="text-orange-500 text-8xl mb-4 font-bold">Thank You!</h2>
//         <p className="text-white text-2xl">
//           We will review your submission and get back to you soon. A copy has
//           been sent to your email.
//         </p>
//       </div>
//     );
//   }

//   return (
//     <div className="mt-4 bg-orange-100 p-6 rounded-md shadow-md">
//       <p className="mb-4 text-orange-700 text-lg font-medium">
//         Please click the submit button to finish.
//       </p>
//       <button
//         onClick={handleSubmit}
//         className="bg-orange-400 hover:bg-orange-600 text-white px-8 py-3 rounded-md cursor-pointer transition duration-300 ease-in-out shadow-md"
//       >
//         Submit
//       </button>
//     </div>
//   );
// }

export default function Submit() {
  return (
    <div>
      <h2 className="mb-10 text-7xl">That's it !</h2>
      <h3 className="mb-10 text-2xl">Please click Submit Button</h3>
      <input
        className="bg-gradient-to-r from-orange-400 to-red-500 text-white font-sans py-4 px-6 rounded-full"
        type="submit"
      />
    </div>
  );
}
