import React from "react";
import TitleBlock from "../blocks/TitleBlock";

export default function ClientEmail({ register, errors }) {
  const fieldName = "clientemail";

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) || "Invalid email";
  };

  return (
    <div>
      <TitleBlock title={"What is the best E-mail for you?"} />

      <div className="relative fade-in-up">
        <input
          className="bg-black text-center w-full text-orange-400 placeholder-grey-600  focus:placeholder-gray-500 py-5  text-3xl md:text-4xl border-0 border-b-2 border-orange-500 focus:border-0 focus:border-b-4 focus:border-orange-500 focus:outline-none"
          {...register(fieldName, {
            required: "This field is required",
            validate: validateEmail,
          })}
        />
        {errors[fieldName] && (
          <span className="text-red-500">{errors[fieldName].message}</span>
        )}
      </div>
    </div>
  );
}
