import TitleBlock from "../blocks/TitleBlock";

export default function ClientName({ register, errors }) {
  const fieldName = "clientname";

  return (
    <div className="relative fade-in-up">
      <TitleBlock title={"What is your name?"} />
      <div>
        <input
          className="bg-black text-orange-400 placeholder-grey-600 focus:placeholder-gray-500 w-full py-4 text-3xl md:text-5xl border-0 border-b-2 border-orange-500 focus:border-0 focus:border-b-4 focus:border-orange-500 focus:outline-none text-center"
          {...register(fieldName, { required: true })}
        />
        {errors[fieldName] && (
          <span className="text-red-500">This field is required</span>
        )}
      </div>
    </div>
  );
}
