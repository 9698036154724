import TitleBlock from "../blocks/TitleBlock";

export default function QuestionNewWebsite({ register, errors }) {
  const fieldName = "questionnewwebsite";

  return (
    <div className="fade-in-up">
      <TitleBlock title={"Is this new website? *"} />
      <div>
        <select
          className="bg-black  w-full text-orange-400 placeholder-grey-600 focus:placeholder-gray-500 py-5 text-3xl md:text-2xl border-0 border-b-2 border-orange-500 focus:border-0 focus:border-b-4 focus:border-orange-500 focus:outline-none"
          {...register(fieldName, { required: true })}
        >
          <option value="">Select an option</option>
          <option value="new">New website on a new domain name</option>
          <option value="replace">Replacing existing/outdated website</option>
        </select>
        {errors[fieldName] && <span>This field is required</span>}
      </div>
    </div>
  );
}
