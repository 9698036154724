export default function IntroQuestion2({ register, errors }) {
  return (
    <div>
      <div className="flex flex-col items-center space-y-4 p-4 fade-in-up">
        <h2 className="text-center font-sans text-2xl leading-relaxed">
          We'll inquire about your business venture, the brand identity, your
          target audience, this particular project, and lastly, about your
          preferences. Shall we begin?
        </h2>
      </div>
    </div>
  );
}
