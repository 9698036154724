import React, { useState, useEffect } from "react";

export default function ContentUpdateFrequency({ register, errors }) {
  const fieldName = "contentUpdateFrequency";
  const [showNewChoice, setShowNewChoice] = useState(false);

  useEffect(() => {
    register(`${fieldName}.other`); // Register the field outside the render method
  }, [register]);

  return (
    <div className="p-4  rounded fade-in-up">
      <h2 className="text-2xl font-semibold mb-4 text-white">
        How often do you think you will be updating content on the website?
      </h2>

      <div className="space-y-4">
        {[
          { value: "daily", label: "Daily" },
          { value: "weekly", label: "Weekly" },
          { value: "monthly", label: "Monthly" },
        ].map((item) => (
          <div
            key={item.value}
            className="flex items-center pl-4 border border-orange-500 rounded"
          >
            <input
              id={`${fieldName}-${item.value}`}
              type="radio"
              className="w-5 h-5 text-orange-500 bg-gray-700 border-orange-500 rounded focus:ring-orange-500 focus:ring-2"
              value={item.value}
              {...register(`${fieldName}.choice`, { required: false })}
            />
            <label
              htmlFor={`${fieldName}-${item.value}`}
              className="w-full py-4 ml-2 text-lg font-medium text-white"
            >
              {item.label}
            </label>
          </div>
        ))}

        <div className="flex items-center pl-4 border border-orange-500 rounded">
          <input
            id={`${fieldName}-other`}
            type="checkbox"
            className="w-5 h-5 text-orange-500 bg-gray-700 border-orange-500 rounded focus:ring-orange-500 focus:ring-2"
            value="other"
            onChange={(e) => setShowNewChoice(e.target.checked)}
          />
          <label
            htmlFor={`${fieldName}-other`}
            className="w-full py-4 ml-2 text-lg font-medium text-white"
          >
            Add new choice
          </label>
        </div>

        {errors[fieldName] && (
          <span className="text-red-400 mt-2 block">
            This field is required
          </span>
        )}
      </div>

      {showNewChoice && (
        <div className="mt-4">
          <input
            type="text"
            placeholder="Enter new choice"
            className="w-full bg-gray-700 text-white px-4 py-3 rounded-md"
            {...register(`${fieldName}.otherText`)}
          />
        </div>
      )}
    </div>
  );
}
