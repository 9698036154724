export default function IntroQuestion({ register }) {
  return (
    <div className="flex flex-col items-center space-y-4 p-4 fade-in-up">
      <h2 className="text-center font-sans text-2xl leading-relaxed ">
        Let us capture your vision and values so we can help you build a vibrant
        brand identity.
      </h2>
      <p className="text-center font-sans">
        Before we move on, please take some time to find a few websites you like
        and keep a record of them.
      </p>
    </div>
  );
}
