import React, { useState } from "react";
import { useForm } from "react-hook-form";
import IntroQuestion from "./components/intro";
import IntroQuestion2 from "./components/intro2";
import CompanyName from "./components/company_name";
import Submit from "./components/submit";
import NextButton from "./components/NextButton";
import PrevButton from "./components/PreviousButton";
import CheckBoxExpectations from "./components/checkbox_expectations";
import ClientName from "./components/client_name";
import ClientEmail from "./components/client_email";
import ClientPhone from "./components/client_phone";
import CompanyOverview from "./components/company_overview";
import Audience from "./components/audience";
import QuestionNewWebsite from "./components/question_new_website";
import ListWebsiteYouLike from "./components/list_websites";
import Functionality from "./components/functionality";
import LookAndFeelPreferences from "./components/lookandfeel";
import QuestionSelling from "./components/question_selling";
import ExistingPhotography from "./components/existingphotography";
import ContentReadiness from "./components/contentreadiness";
import ContentUpdateFrequency from "./components/contentupdatefrequency";
import WebsiteLaunchTimeline from "./components/websitelaunchtimeline";
import AdditionalInfo from "./components/additionalinfo";
import ReferralSource from "./components/referralsource";
import IntroCoding from "./components/introcoding";
import axios from "axios";

const steps = [
  IntroCoding,
  IntroQuestion,
  IntroQuestion2,
  CompanyName,
  ClientName,
  ClientEmail,
  ClientPhone,
  CompanyOverview,
  Audience,
  QuestionNewWebsite,
  ListWebsiteYouLike,
  Functionality,
  CheckBoxExpectations,
  LookAndFeelPreferences,
  QuestionSelling,
  ExistingPhotography,
  ContentReadiness,
  ContentUpdateFrequency,
  WebsiteLaunchTimeline,
  AdditionalInfo,
  ReferralSource,
  Submit,
];

export default function RequirementForm() {
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onBlur", criteriaMode: "all" });
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (data) => {
    console.log(data);
    setIsLoading(true);
    try {
      const requestData = { data: data };
      const response = await axios.post(
        "https://ub0967ih47.execute-api.ap-southeast-2.amazonaws.com/default/wdc",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check if the request was successful
      if (response.status === 200) {
        setIsSubmitted(true);
      } else {
        // Handle non-successful responses
        console.error("Response not OK:", response);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Sorry an error occurred please contact Admin");
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = async () => {
    const isCurrentStepValid = await trigger();
    if (isCurrentStepValid) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const CurrentComponent = steps[currentStep];

  let nextButtonText;
  if (currentStep === 0) {
    nextButtonText = "Get Started";
  } else if (currentStep === 1 || currentStep === 2) {
    nextButtonText = "Yes, Let's go";
  } else {
    nextButtonText = "Next";
  }

  return (
    <div>
      {isLoading ? (
        <div>
          {" "}
          <button
            disabled
            type="button"
            className="py-2.5 px-5 me-2 text-sm font-medium text-white-900 bg-orange-500 rounded-sm hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
          >
            <svg
              aria-hidden="true"
              role="status"
              className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#1C64F2"
              />
            </svg>
            Processing...
          </button>
        </div>
      ) : isSubmitted ? (
        <div>
          Thank you for providing your project requirements. Your submission has
          been successfully received. We will review the details and get back to
          you promptly with further steps or any necessary clarifications.
        </div>
      ) : (
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <CurrentComponent register={register} errors={errors} />

          <div className="flex flex-row justify-center gap-4 mt-10">
            {currentStep > 2 && currentStep < steps.length - 1 && (
              <PrevButton onClick={prevStep} text="Go Back" />
            )}
            {currentStep < steps.length - 1 && (
              <NextButton onClick={nextStep} text={nextButtonText} />
            )}
          </div>
        </form>
      )}
    </div>
  );
}
