import React from "react";
import logo from "../../images/logo.svg";

export default function IntroCoding() {
  return (
    <>
      <div className=" top-2 flex justify-center mt-10 mb-10 fade-in-up">
        <img
          src={logo}
          alt="Company Logo"
          className="h-32 md:h-32  w-auto mx-auto"
        />
      </div>
      <div className=" top-24 flex justify-center w-full text-center px-4 mb-11">
        <p className="text-white text-2xl italic">
          "Design is the silent ambassador of your brand."
          <br />
          <span className="text-gray-400 text-lg">- Paul Rand</span>
        </p>
      </div>
    </>
  );
}
