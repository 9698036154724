import React from "react";
import PropTypes from "prop-types";

function TitleBlock({ title, subtitle }) {
  return (
    <div className="flex flex-col items-center space-y-4 p-4">
      {title && <h2 className="text-center font-sans text-2xl">{title}</h2>}
      {subtitle && <h3 className="text-center">{subtitle}</h3>}
    </div>
  );
}

TitleBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

TitleBlock.defaultProps = {
  title:
    "Let us capture your vision and values so we can help you build a vibrant brand identity",
  subtitle: null,
};

export default TitleBlock;
