import React, { useState, useEffect } from "react";
import TitleBlock from "../blocks/TitleBlock";

export default function LookAndFeelPreferences({ register, errors }) {
  const fieldName = "lookAndFeelPreferences";
  const [showNewChoice, setShowNewChoice] = useState(false);

  useEffect(() => {
    register(`${fieldName}.other`); // Register the field outside the render method
  }, [register]);

  return (
    <div className="p-4  rounded fade-in-up">
      <TitleBlock
        title={"Do you have any opinions about the Look & Feel of your site? *"}
      />
      <div className="space-y-3">
        <div className="flex items-center pl-4 border border-orange-500 rounded mb-2">
          <input
            id={`${fieldName}-leaveToTeam`}
            type="radio"
            className="w-5 h-5 text-orange-500 bg-gray-700 border-orange-500 rounded focus:ring-orange-500 focus:ring-2"
            value="leaveToTeam"
            {...register(`${fieldName}.choice`, { required: false })}
          />
          <label
            htmlFor={`${fieldName}-leaveToTeam`}
            className="w-full py-4 ml-2 text-lg font-medium text-white"
          >
            Not really, I'm happy to leave it to your design team
          </label>
        </div>

        <div className="flex items-center pl-4 border border-orange-500 rounded mb-2">
          <input
            id={`${fieldName}-moreDetail`}
            type="radio"
            className="w-5 h-5 text-orange-500 bg-gray-700 border-orange-500 rounded focus:ring-orange-500 focus:ring-2"
            value="moreDetail"
            {...register(`${fieldName}.choice`, { required: false })}
          />
          <label
            htmlFor={`${fieldName}-moreDetail`}
            className="w-full py-4 ml-2 text-lg font-medium text-white"
          >
            Yes, and I'd like to go into some more detail about it
          </label>
        </div>

        <div className="flex items-center pl-4 border border-orange-500 rounded mb-2">
          <input
            id={`${fieldName}-haveDesigns`}
            type="radio"
            className="w-5 h-5 text-orange-500 bg-gray-700 border-orange-500 rounded focus:ring-orange-500 focus:ring-2"
            value="haveDesigns"
            {...register(`${fieldName}.choice`, { required: false })}
          />
          <label
            htmlFor={`${fieldName}-haveDesigns`}
            className="w-full py-4 ml-2 text-lg font-medium text-white"
          >
            I've already got designs drawn up
          </label>
        </div>

        <div className="flex items-center pl-4 border border-orange-500 rounded mb-2">
          <input
            id={`${fieldName}-other`}
            type="checkbox"
            className="w-5 h-5 text-orange-500 bg-gray-700 border-orange-500 rounded focus:ring-orange-500 focus:ring-2"
            value="other"
            onChange={(e) => setShowNewChoice(e.target.checked)}
          />
          <label
            htmlFor={`${fieldName}-other`}
            className="w-full py-4 ml-2 text-lg font-medium text-white"
          >
            Add new choice
          </label>
        </div>

        {errors[fieldName] && (
          <span className="text-red-400">This field is required</span>
        )}
      </div>

      {showNewChoice && (
        <div className="mt-4">
          <input
            type="text"
            placeholder="Enter new choice"
            className="w-full bg-gray-700 text-white px-4 py-3 rounded-md"
            {...register(`${fieldName}.otherText`)}
          />
        </div>
      )}
    </div>
  );
}
